import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, BrowserTransferStateModule, Meta, TransferState, Title } from '@angular/platform-browser';
import { DatePipe, Location, registerLocaleData } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgModule, LOCALE_ID } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './common/components/root/app.component';
import { Client } from './common/services/api/client';
import { ContentUploadComponent } from './common/components/content-upload/content-upload.component';
import { createTranslateLoader } from './common/services/api/util';
import { declarations, appRoutes } from './app.router';
import { DialogComponent } from './common/components/dialog/dialog.component';
import { DialogService } from './common/components/dialog/dialog.service';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { LocalizeRouterHttpLoader } from 'localize-router-http-loader';
// import { LocalizeRouterModule, LocalizeRouterSettings, LocalizeParser, LocalizeRouterService } from 'localize-router';
import { Pilot } from './common/services/api/pilot';
import { PilotAuthGuard, ClientAuthGuard, FrameGuard } from './common/services/api/util';
import { SharedComponentsModule } from './common/components/shared.components.module';
import { SharedServicesModule } from './common/services/shared.services.module';
import { TokenInterceptor } from './common/services/overrides/token.interceptor';
import { TranslateBrowserLoader } from './common/services/translate-browser-loader.service';
import { UiLanguageService } from './common/services/ui-language.service';
import { VerifyPhoneService } from './common/services/api/verify-phone';

import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);

// Required for AoT
/*export function LocalizeHttpLoaderFactory(
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings,
  http: HttpClient) {
  return new LocalizeRouterHttpLoader(translate, location, settings, http);
}*/

import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {
  MatButtonModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatGridListModule,
  MatIconModule,
  MatIconRegistry,
  MatInputModule,
  MatMenuModule,
  MatRadioModule,
  MatSelectModule,
  MatStepperModule
} from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMomentDateModule } from '@angular/material-moment-adapter';

@NgModule({
  imports: [
    CdkStepperModule,
    CdkTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatMomentDateModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule
  ],
  exports: [
    CdkStepperModule,
    CdkTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatMomentDateModule,
    MatRadioModule,
    MatSelectModule,
    MatStepperModule
  ]
})
export class MaterialModule {}

@NgModule({
  declarations: [
    AppComponent,
    ContentUploadComponent,
    DialogComponent,
    declarations,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'ff2-fe' }),
    HttpClientModule,
    BrowserTransferStateModule,
    FormsModule,
    /*LocalizeRouterModule.forRoot(appRoutes, {
      parser: {
          provide: LocalizeParser,
          useFactory: LocalizeHttpLoaderFactory,
          deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
      }
    }),*/
    MaterialModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes,
      { enableTracing: false } // <-- debugging purposes only
    ),
    SharedComponentsModule,
    SharedServicesModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, TransferState]
      }
    }),
  ],
  entryComponents: [
    DialogComponent
  ],
  providers: [
    Client,
    ClientAuthGuard,
    FrameGuard,
    DatePipe,
    MatIconRegistry,
    Meta,
    Pilot,
    PilotAuthGuard,
    TransferState,
    Title,
    UiLanguageService,
    VerifyPhoneService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'de-DE'
    },
    DialogService
  ],
  bootstrap: [
    AppComponent
  ],
})
export class AppModule {
}












// HTTP Basic Auth (prompted when accessed out of the office)
// dron	IaaAV&=@g,Hv@%/m6lLl
